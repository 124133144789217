<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">전화 상담이력조회</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class>
          <div class="form-inp sm">
            <v-select
              v-model="selected_channel"
              :items="asp_new_cust_Key"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              label="회사구분"
              v-on:change="chnASP"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar ml-2"
                append-icon="svg-calendar"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                v-model="dates[0]"
                no-title
                dark
                color="#F7AFAE"
                name="startDate"
                @change="startDate"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                v-model="dates[1]"
                dark
                color="#F7AFAE"
                no-title
                show-current="false"
                :min="dates[0]"
                name="endDate"
                @change="endDate"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn outlined class="btn-default" @click="menu1 = false"
                >확인</v-btn
              >
            </div>
          </v-menu>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="calltypcd"
              :items="mixin_common_code_get(this.common_code, 'PLT007', '전체')"
              outlined
              hide-details
              label="콜유형"
              filled
              dense
              background-color="white"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd"
              :items="srhCnslTypCd"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형1"
              @change="changeCouselTyeKey(asp_cust_key, cnsltypcd, '1')"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd2"
              :items="srhCnslTypCd2"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형2"
              @change="changeCouselTyeKey(asp_cust_key, cnsltypcd2, '2')"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd3"
              :items="srhCnslTypCd3"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형3"
              @change="changeCouselTyeKey(asp_cust_key, cnsltypcd3, '3')"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="cnsltypcd4"
              :items="srhCnslTypCd4"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="상담유형4"
              @change="changeCouselTyeKey(asp_cust_key, cnsltypcd4, '4')"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <div class="mt-2">
            <v-text-field
              class="form-inp sm"
              name="userId"
              v-model="userId"
              label="고객 ID"
              outlined
              hide-details
            ></v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="userNm"
              v-model="userNm"
              label="고객 이름"
              outlined
              hide-details
            ></v-text-field>
            <!-- 조회 버튼 -->
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnPopupCus')"
              dark
              small
              class="btn-main-search pa-0 ml-2"
              min-width="32px"
              height="32px"
              plain
              @click="btnCus(), showDialog('MP01')"
            >
              <v-icon small class="svg-main-searh white"></v-icon>
            </v-btn>
            <!-- 새로고침 버튼 -->
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnInitCus')"
              dark
              small
              class="btn-main-refresh pa-0 ml-2"
              min-width="32px"
              height="32px"
              @click="clear0"
              plain
            >
              <v-icon small class="svg-main-refresh"></v-icon>
            </v-btn>
            <div class="form-inp sm ml-2">
              <v-select
                :items="userGroup"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="사용자그룹"
                placeholder="선택하세요"
                v-model="selected_userGroup"
              ></v-select>
            </div>
            <v-text-field
              class="form-inp sm ml-2"
              name="userId1"
              v-model="userId1"
              label="사용자 ID"
              outlined
              hide-details
            ></v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="userNm1"
              v-model="userNm1"
              label="사용자 이름"
              outlined
              hide-details
            ></v-text-field>
            <v-text-field
              class="form-inp sm ml-2"
              name="userNick1"
              v-model="userNick1"
              label="사용자 닉네임"
              outlined
              hide-details
            ></v-text-field>
            <!-- 조회 버튼 -->
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')"
              dark
              small
              class="btn-main-search pa-0 ml-2"
              min-width="32px"
              height="32px"
              plain
              @click="btnUser(), showDialog('MP02')"
            >
              <v-icon small class="svg-main-searh white"></v-icon>
            </v-btn>
            <!-- 새로고침 버튼 -->
            <v-btn
              v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')"
              dark
              small
              class="btn-main-refresh pa-0 ml-2"
              min-width="32px"
              height="32px"
              @click="clear1"
              plain
            >
              <v-icon small class="svg-main-refresh"></v-icon>
            </v-btn>
          </div>
          <div class="form-inp free">
            <div class="form-inp free">
              <v-select
                single-line
                v-model="CHK_NEWTIME"
                :items="CHK_TIME"
                item-value="value"
                item-text="text"
                outlined
                label="상담일자"
                multiple
                hide-details
                v-on:change="[chgChkTime(),chkNoTime()]"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 4" small>
                    <span>{{ item.text }}</span>
                    <template>
                      <v-btn
                        icon
                        x-small
                        class="svg-close20 ml-1"
                        v-show="dropItemRemove === true"
                        @click.stop="removeDropTime(item)"
                      ></v-btn>
                    </template>
                  </v-chip>
                  <span v-if="index === 3" class="grey--text text-caption">
                    ...
                  </span>
                </template>
              </v-select>
            </div>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAllCheck')" outlined class="btn-default ml-2" @click="allClick"
              >전체선택</v-btn
            >
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAllUncheck')" outlined class="btn-default ml-2" @click="[allCloseClick(),chkNoTime()]"
              >전체해제</v-btn
            >
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnWork')" outlined class="btn-default ml-2" @click="selectTime"
              >업무시간선택</v-btn
            >
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnTurn')" outlined class="btn-default ml-2" @click="selectTurn"
              >선택반전</v-btn
            >

          </div>
            <div class="form-inp free ml-2">
            <v-text-field
              class="form-inp sm ml-2"
              name="CALL_TIME"
              v-model="CALL_TIME"
              label="녹음시간"
              outlined
              hide-details
            ></v-text-field>
            </div>
            <span>  초</span>
            <div class="form-inp sm ml-2">
              <v-select
                v-model="SEL_NEWUPDOWN"
                :items="SEL_UPDOWN"
                item-value="CD"
                item-text="CD_NM"
                outlined
                hide-details
                label="범위"
              >
                <template v-slot:label>
                  범위
                </template>
              </v-select>
            </div>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 ml-3" v-on:click="search('N')">조회</v-btn>
        </div>
      </div>
    </div>
    <!-- 상담이력조회 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        상담이력조회 목록
        <div class="ml-auto">
          <v-text-field
            class="form-inp sm ml-2"
            name="SEL_DATA_NUM"
            v-model="SEL_DATA_NUM"
            label="엑셀 데이터 개수"
            outlined
            hide-details
          ></v-text-field>
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnExcelDown')"
            outlined
            small
            class="btn-point ml-2"
            @click="search('Y')"
          >
            <v-icon small>mdi-arrow-collapse-down</v-icon>엑셀다운로드
          </v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
          dense
          height="320px"
          :headers="gridDataHeaders"
          :items="gridDataText"
          single-select
          item-key="ROW_NUMBER"
          :items-per-page="itemsPerPage"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          @page-count="pageCount = $event"
          @click:row="showRowInfo"
          :item-class="isActiveRow"
          fixed-header
        >
          <template v-slot:item.BTN_01="{ item }">
            <!--
              버튼권한을 줄 수 없음
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnHear')" outlined small class="btn-point ml-2">녹취</v-btn> 
            -->
            <v-btn outlined small class="btn-point ml-2">녹취</v-btn> 
          </template>
          <template v-slot:item.CNSL_DATE="{ item }">
            <div>{{ dateFormat(item.CNSL_DATE) }}</div>
          </template>
          <template v-slot:item.CNSL_BEGIN_TIME="{ item }">
            <div>{{ timeFormat(item.CNSL_BEGIN_TIME) }}</div>
          </template>
          <template v-slot:item.CUST_NO="{ item }">
            <div>{{ juminFormat(item.CUST_NO) }}</div>
          </template>
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>

        <!--다운로드 받을 테이블-->
        <v-data-table
          dense
          height="0px"
          :headers="gridDataHeaders"
          :items="downGridDataText"
          single-select
          item-key="ROW_NUMBER"
          :items-per-page="pagination.rowsPerPage"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          @click:row="showRowInfo"
          id="downTable"
          :item-class="isActiveRow"
          fixed-header
        >
        </v-data-table>
        <!--보이지 않는 테이블-->

      </div>
    </div>
    <!--// 상담이력조회 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">녹취재생</h2>
      <v-card-text>
        <v-row class="black--text">
          <audio 
            controls="" 
            autoplay="true" 
            name="media"
            :src="REC_CALL"
            type="audio/mpeg"
          >
          </audio>
        </v-row>
      </v-card-text>
    </div>
    <!-- 상담이력상세정보 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">상담이력 상세정보</h2>
      <div class="box-ct">
        <div class="table-form">
          <table>
            <caption class="hide">
              상담이력 상세정보
            </caption>
            <colgroup>
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">상담시작일자</th>
                <td>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-calendar"
                        disabled
                        :value="dateFormat(contactDetail.CNSL_DATE)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#F7AFAE"
                      no-title
                      @input="menu2 = false"
                      disabled
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">상담시작시간</th>
                <td>
                  <v-menu
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-time"
                        disabled
                        :value="timeFormat(contactDetail.CNSL_BEGIN_TIME)"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @input="menu5 = false"
                      disabled
                    ></v-time-picker>
                  </v-menu>
                </td>
                <th scope="row">콜유형</th>
                <td>
                  <v-text-field
                    v-model="contactDetail.CALL_TY_NM"
                    class="form-inp full"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th scope="row">인가번호</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    :disabled="true"
                    value="인가번호"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th scope="row">고객전화번호</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    :disabled="true"
                    outlined
                    hide-details
                    :value="phoneHyphen(contactDetail.CUST_TEL_NO)"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담종료일자</th>
                <td>
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-calendar"
                        disabled
                        :value="dateFormat(contactDetail.CNSL_EOT_DATE)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#F7AFAE"
                      no-title
                      @input="menu3 = false"
                      disabled
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">상담종료시간</th>
                <td>
                  <v-menu
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-time"
                        disabled
                        :value="timeFormat(contactDetail.CNSL_EOT_TIME)"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @input="menu5 = false"
                      disabled
                    ></v-time-picker>
                  </v-menu>
                </td>
                <th scope="row">사용자명</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.CSLT_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th scope="row">고개명</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.CUST_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th scope="row">발신번호</th>
                <td>
                  <v-text-field
                    class="form-inp fullsm"
                    v-model="contactDetail.SEND_MAN_NO"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담유형</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.CNSL_TYP_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.CNSL_TYP_NM_2"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.CNSL_TYP_NM_3"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <td colspan="2">
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.CNSL_TYP_NM_4"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th scope="row">녹취파일명</th>
                <td colspan="3">
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.RDWT_FILE_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row" rowspan="2">비고</th>
                <td rowspan="2" colspan="5">
                  <v-textarea
                    class="textarea-type0"
                    label
                    :value="contactDetail.CNSL_CNTN"
                    no-resize
                    outlined
                    disabled
                  ></v-textarea>
                </td>
                <th scope="row">완료여부</th>
                <td colspan="3">
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.PROC_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">미완료사유</th>
                <td colspan="3">
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.PROC_MDCLS_NM"
                    :sdisabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--// 고객상세정보 -->
    <div calss>
      <v-dialog
        max-width="1400"
        max-height="1400"
        v-model="dialogMP02"
        content-class="square-modal"
      >
        <dialog-MP02
          headerTitle="사용자정보"
          @hide="hideDialog('MP02')"
          :userInfo="userInfo"
          @input="userInfo = $event"
          @selectUser="[hideDialog('MP02'), setUser()]"
        ></dialog-MP02>
        <template v-slot:body></template>
      </v-dialog>
    </div>

    <div calss>
      <v-dialog
        max-width="1400"
        max-height="1400"
        v-model="dialogMP01"
        content-class="square-modal"
      >
        <dialog-MP01
          headerTitle="고객정보"
          @hide="hideDialog('MP01')"
          :cusInfo="cusInfo"
          @input="cusInfo = $event"
          @selectCus="[hideDialog('MP01'), setCus()]"
        ></dialog-MP01>
        <template v-slot:body></template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import XLSX from "xlsx";
import axios from "axios";
import { mapGetters } from "vuex";
import dialogMP01 from "./MP01.vue";
import dialogMP02 from "./MP02.vue";
import api from "@/store/apiUtil.js";

export default {
  name: "MENU_M110201", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    dialogMP01,
    dialogMP02,
  },
  data() {
    return {
      dialogMP01: false,
      dialogMP02: false,
      userInfo: {},
      cusInfo: {},
      userGroup: [], //사용자그룹
      params: { userId: "", userNm: "", aspNewcustKey: "" },
      common_code: [],
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      timeStep: "10:10",
      asp_cust_key: "001",
      selected_channel: "",
      selected_userGroup: "",
      calltypcd: "",
      cnsltypcd: "",
      cnsltypcd2: "",
      cnsltypcd3: "",
      cnsltypcd4: "",
      userId: "",
      userNm: "",
      userId1: "",
      userNm1: "",
      userNick1: "",
      asp_new_cust_Key: this.$store.getters["userStore/GE_USER_ROLE"].company,
      srhCallTypCd: [], //콜유형
      srhInqryTypCd: [], //문의유형1
      srhInqryTypCd2: [],
      srhCnslTypCd: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ], //상담유형
      srhCnslTypCd2: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ], //상담유형2
      srhCnslTypCd3: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ], //상담유형3
      srhCnslTypCd4: [
        {
          CD: "",
          CD_NM: "전체",
        },
      ], //상담유형4
      contactDetail: [], //상담내용저장
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],

      gridDataHeaders: [
        { align: "center", width: "20px", sortable: false },
        {
          text: "NO",
          value: "ROW_NUMBER",
          align: "center",
          width: "30px",
          sortable: false,
        },
        {
          text: "녹취청취",
          value: "BTN_01",
          align: "center",
          sortable: false,
          width: "100px",
        },
        //{ text: "상담이력번호", value: "CNSL_HIST_NO", align: " d-none", width: "0px", sortable: false },
        //{ text: "상담이력상세번호", value: "CNSL_HIST_DTL_NO", align: " d-none", width: "30px", sortable: false, },
        //{ text: "녹취ID", value: "RDWT_ID", align: " d-none", width: "30px", sortable: false, },
        {
          text: "상담일자",
          value: "CNSL_DATE",
          align: "center",
          sortable: true,
          width: "100px",
        },
        //{ text: "상담시작일자", value: "CNSL_BEGIN_DATE", align: " d-none", sortable: true, width: "100px", },
        {
          text: "상담시작시간",
          value: "CNSL_BEGIN_TIME",
          align: "center",
          sortable: true,
          width: "120px",
        },
        {
          text: "통화시간",
          value: "TEL_TIME",
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "콜유형",
          value: "CALL_TY_NM",
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "콜기준여부",
          value: "RDWT_SEND_YN",
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "통화자",
          value: "PROC_CODE_SCLS_NM",
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담원명",
          value: "CSLT_NM",
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "고객명",
          value: "CUST_NM",
          align: "center",
          sortable: true,
          width: "120px",
          cellClass: "ellipse ",
        },
        {
          text: "고객주민번호",
          value: "CUST_NO",
          align: "center",
          sortable: true,
          width: "150px",
        },
        {
          text: "발신자번호",
          value: "SEND_MAN_NO",
          align: "center",
          sortable: true,
          width: "100px",
        },
        //{ text: "상담사ID", value: "CSLT_ID", align: " d-none", sortable: true, width: "100px", },
        //{ text: "상담유형", value: "CNSL_TYP_CD", align: " d-none", sortable: true, width: "100px", },
        {
          text: "상담유형",
          value: "CNSL_TYP_NM",
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담유형2",
          value: "CNSL_TYP_NM_2",
          align: "center",
          sortable: true,
          width: "100px",
        },
        // { text: "상담유형2", value: "CNSL_TYP_CD_2", align: " d-none", sortable: true, width: "100px", },
        {
          text: "상담유형3",
          value: "CNSL_TYP_NM_3",
          align: "center",
          sortable: true,
          width: "100px",
        },
        //{ text: "상담유형3", value: "CNSL_TYP_CD_3", align: " d-none", sortable: true, width: "100px", },
        {
          text: "상담유형4",
          value: "CNSL_TYP_NM_4",
          align: "center",
          sortable: true,
          width: "100px",
        },
        //{ text: "상담유형4", value: "CNSL_TYP_CD_4", align: " d-none", sortable: true, width: "100px", },
        //{ text: "완료코드", value: "PROC_LCLS_NM", align: " d-none", sortable: true, width: "100px", },
        //{ text: "미완료사유", value: "PROC_MDCLS_NM", align: " d-none", sortable: true, width: "100px", },
        {
          text: "완료여부",
          value: "PROC_NM",
          align: "center",
          sortable: true,
          width: "100px",
        },
        //{ text: "고객전화번호", value: "CUST_TEL_NO", align: " d-none", sortable: true, width: "100px", },
        //{ text: "상담종료일자", value: "CNSL_EOT_DATE", align: " d-none", sortable: true, width: "100px", },
        {
          text: "녹취파일명",
          value: "RDWT_FILE_NM",
          align: "center",
          sortable: true,
          width: "100px",
          cellClass: "ellipse ",
        },
        {
          text: "상담내용",
          value: "CNSL_CNTN",
          align: "center",
          sortable: true,
          width: "100px",
          cellClass: "ellipse ",
        },
        //{ text: "콜유형ID", value: "CALL_TY", align: " d-none", sortable: true, width: "100px", },
        //{ text: "상담종료시간", value: "CNSL_EOT_TIME", align: " d-none", sortable: true, width: "100px", },
        //{ text: "등록일자", value: "REG_DTIM", align: " d-none", sortable: true, width: "100px", },
        //{ text: "완료코드", value: "PROC_CODE_LCLS", align: " d-none", sortable: true, width: "100px", },
        //{ text: "미완료사유", value: "PROC_CODE_MDCLS", align: " d-none", sortable: true, width: "100px", },
        //{ text: "고객주민번호(더미)", value: "CUST_NO", align: " d-none", sortable: true, width: "100px", },
        //{ text: "발신자번호(더미)", value: "SEND_MAN_NO", align: " d-none", sortable: true, width: "100px", },
      ],
      gridDataText: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      totalVisible: 10,
      pagination: { page: 1, rowsPerPage: 100, sortBy: "", descending: "" },
      textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
      textareaValue: "200자 제한!",
      selectedRow: [],

      CHK_TIME: [
        {
          text: "00시",
          selected: true,
          value: "00",
        },
        {
          text: "01시",
          selected: true,
          value: "01",
        },
        {
          text: "02시",
          selected: true,
          value: "02",
        },
        {
          text: "03시",
          selected: true,
          value: "03",
        },
        {
          text: "04시",
          selected: true,
          value: "04",
        },
        {
          text: "05시",
          selected: true,
          value: "05",
        },
        {
          text: "06시",
          selected: true,
          value: "06",
        },
        {
          text: "07시",
          selected: true,
          value: "07",
        },
        {
          text: "08시",
          selected: true,
          value: "08",
        },
        {
          text: "09시",
          selected: true,
          value: "09",
        },
        {
          text: "10시",
          selected: true,
          value: "10",
        },
        {
          text: "11시",
          selected: true,
          value: "11",
        },
        {
          text: "12시",
          selected: true,
          value: "12",
        },
        {
          text: "13시",
          selected: true,
          value: "13",
        },
        {
          text: "14시",
          selected: true,
          value: "14",
        },
        {
          text: "15시",
          selected: true,
          value: "15",
        },
        {
          text: "16시",
          selected: true,
          value: "16",
        },
        {
          text: "17시",
          selected: true,
          value: "17",
        },
        {
          text: "18시",
          selected: true,
          value: "18",
        },
        {
          text: "19시",
          selected: true,
          value: "19",
        },
        {
          text: "20시",
          selected: true,
          value: "20",
        },
        {
          text: "21시",
          selected: true,
          value: "21",
        },
        {
          text: "22시",
          selected: true,
          value: "22",
        },
        {
          text: "23시",
          selected: true,
          value: "23",
        },
      ],

      CHK_NEWTIME: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      dropItemRemove: true,

      CALL_TIME:'',

      SEL_UPDOWN:[{
        CD: 'UP',
        CD_NM:'이상'
      },
      {
        CD: 'DOWN',
        CD_NM:'이하'
      }
      ],
      SEL_NEWUPDOWN:'',

      downGridDataText:[],
      SEL_DATA_NUM:'',

      REC_CALL:'',
    };
  },

  async mounted() {
    //공통코드설정
    let codeName = ["PLT007"];
    this.common_code = await this.mixin_common_code_get_all(codeName);

    this.userGroup = await this.mixin_attr_get(this.asp_cust_key, "B", "전체");
  },

  methods: {
    async chnASP() {
      this.srhCnslTypCd = [{ CD: "", CD_NM: "전체" }];
      this.cnsltypcd = "";
      this.srhCnslTypCd2 = [{ CD: "", CD_NM: "전체" }];
      this.cnsltypcd2 = "";
      this.srhCnslTypCd3 = [{ CD: "", CD_NM: "전체" }];
      this.cnsltypcd3 = "";
      this.srhCnslTypCd4 = [{ CD: "", CD_NM: "전체" }];
      this.cnsltypcd4 = "";
      this.srhCnslTypCd = await this.mixin_counsel_type_code_get(
        this.selected_channel,
        "*",
        "전체"
      );
    },
    //시작일자
    startDate(e) {
      this.dates[0] = e;
    },
    //종료일자
    endDate(e) {
      this.dates[1] = e;
    },
    allowedStep: (m) => m % 10 === 0,

    btnUser() {
      var user = {
        ASP_CUST_KEY: this.selected_channel,
        USER_ID: this.userId1,
        USER_NM: this.userNm1,
        USER_NICK: this.userNick1,
      };
      this.userInfo = user;
    },
    setUser() {
      this.userId1 = this.userInfo.USER_ID;
      this.userNm1 = this.userInfo.USER_NM;
      this.userNick1 = this.userInfo.USER_NICK;
    },

    btnCus() {
      var user = {
        ASP_CUST_KEY: this.selected_channel,
        CUST_NO: this.userId,
        CUST_NM: this.userNm,
      };
      this.cusInfo = user;
    },
    setCus() {
      this.userId = this.cusInfo.CUST_NO;
      this.userNm = this.cusInfo.CUST_NM;
    },

    showDialog(type) {
      if (this.selected_channel == "" || this.selected_channel == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }

      //팝업 파라미터 설정
      this.popupParams;
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      this[`dialog${type}`] = false;
    },

    //상담유형ChangeEvent
    async changeCouselTyeKey(asp_cust_key, spst_coundel_typ_cd, data_type) {
      let CNSL = await this.mixin_counsel_type_code_get(
        this.selected_channel,
        spst_coundel_typ_cd,
        "전체"
      );

      if (data_type === "1") {
        if (this.cnsltypcd == "" || this.cnsltypcd == undefined) {
          this.srhCnslTypCd2 = [{ CD: "", CD_NM: "전체" }];
          this.cnsltypcd2 = "";
        } else {
          this.srhCnslTypCd2 = CNSL;
          this.cnsltypcd2 = "";
        }
        this.srhCnslTypCd3 = [{ CD: "", CD_NM: "전체" }];
        this.cnsltypcd3 = "";
        this.srhCnslTypCd4 = [{ CD: "", CD_NM: "전체" }];
        this.cnsltypcd4 = "";
      } else if (data_type === "2") {
        if (this.cnsltypcd2 == "" || this.cnsltypcd2 == undefined) {
          this.srhCnslTypCd3 = [{ CD: "", CD_NM: "전체" }];
          this.cnsltypcd3 = "";
        } else {
          this.srhCnslTypCd3 = CNSL;
          this.cnsltypcd3 = "";
        }
        this.srhCnslTypCd4 = [{ CD: "", CD_NM: "전체" }];
        this.cnsltypcd4 = "";
      } else if (data_type === "3") {
        if (this.cnsltypcd3 == "" || this.cnsltypcd3 == undefined) {
          this.srhCnslTypCd4 = [{ CD: "", CD_NM: "전체" }];
          this.cnsltypcd4 = "";
        } else {
          this.srhCnslTypCd4 = CNSL;
          this.cnsltypcd4 = "";
        }
      }
    },

    phoneHyphen(number) {
      //핸드폰 번호 형식 만들기
      if (!number) return "";
      var phoneNum = number.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      return phoneNum;
    },

    dateFormat(date) {
      //날짜 포멧
      if (!date) return "";
      var newDate = "";
      //공백제거
      date = date.replace(/\s/gi, "");

      if (date.length == 8) {
        newDate = date.replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3");
        return newDate;
      } else {
        return date;
      }
    },

    juminFormat(num) {
      //주민 번호 형식 만들기
      var juminNum = num.replace(/-/g, "").replace(/(\d{6})(\d{7})/g, "$1-$2");
      return juminNum;
    },
    timeFormat(time) {
      //접수시간 포맷
      var newTime = "";
      if (!time) return "";

      if (time.length == 6) {
        newTime =
          time.substring(0, 2) +
          ":" +
          time.substring(2, 4) +
          ":" +
          time.substring(4, 6);
        return newTime;
      } else {
        //추후 6자리 외의 데이터가 있을 경우 수정
        return time;
      }
    },

    clear0() {
      this.userId = "";
      this.userNm = "";
    },
    clear1() {
      this.userId1 = "";
      this.userNm1 = "";
      this.userNick1 = "";
    },
    yyyyMMdd: function (value) {
      // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
      if (value == "") return "";

      // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
      var js_date = new Date(value);

      // 연도, 월, 일 추출
      var year = js_date.getFullYear();
      var month = js_date.getMonth() + 1;
      var day = js_date.getDate();

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      // 최종 포맷 (ex - '2021-10-08')
      return year + "" + month + "" + day;
    },
    //상세정보
    showRowInfo(row, value) {
      this.contactDetail = row;
      this.REC_CALL = "https://hkipcc.co.kr/hdd2/rec/" + row.RDWT_FILE_NM + ".mp3"
    },

    isActiveRow(item) {
      const activeClass =
        item.CNSL_HIST_NO === this.contactDetail.CNSL_HIST_NO ? "active" : "";
      return activeClass;
    },

    //엑셀업로드
    exportExcel(table_id, file_name) {
      this.table_id = table_id;
      this.file_name = file_name;
      this.mixin_common_exportExcel();
    },

    async search(DOWN_YN) {
      if (this.selected_channel == "" || this.selected_channel == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }

      let DATA_NUM = this.pagination.rowsPerPage;
      if(DOWN_YN == 'Y'){
        if (this.gridDataText.length == 0) {
          this.showAlert("상담이력 조회 후 다운로드할 수 있습니다.");
          return;
        }
        if(this.SEL_DATA_NUM != '0' && this.SEL_DATA_NUM != '' && this.SEL_DATA_NUM != undefined){
          DATA_NUM = this.SEL_DATA_NUM;
        }
      } else {
        DATA_NUM = this.pagination.rowsPerPage;
      }

      let head = "";
      let dataa = "";
      this.URLData = "/api/phone/history/inqire/list";
      this.HEADER_SERVICE = "phone.history.inqire";
      this.HEADER_METHOD = "list";
      this.HEADER_TYPE = "BIZ_SERVICE";

      await api
        .post(
          this.URLData,
          {
            ASP_NEWCUST_KEY: this.selected_channel,
            SEARCH_DT_FROM: this.yyyyMMdd(this.dates[0]),
            SEARCH_DT_TO: this.yyyyMMdd(this.dates[1]),
            CALL_TY: this.calltypcd,
            CNSL_TYP_CD: this.cnsltypcd,
            CNSL_TYP_CD_2: this.cnsltypcd2,
            CNSL_TYP_CD_3: this.cnsltypcd3,
            CNSL_TYP_CD_4: this.cnsltypcd4,
            CUST_NO: this.userId,
            CUST_NM: this.userNm,
            TM_KIND: this.selected_userGroup, //사용자그룹
            CSLT_ID: this.userId1,
            CSLT_NM: this.userNm1,
            CSLT_NICK: this.userNick1,
            SEARCH_TIME_0: this.CHK_NEWTIME[0],
            SEARCH_TIME_1: this.CHK_NEWTIME[1],
            SEARCH_TIME_2: this.CHK_NEWTIME[2],
            SEARCH_TIME_3: this.CHK_NEWTIME[3],
            SEARCH_TIME_4: this.CHK_NEWTIME[4],
            SEARCH_TIME_5: this.CHK_NEWTIME[5],
            SEARCH_TIME_6: this.CHK_NEWTIME[6],
            SEARCH_TIME_7: this.CHK_NEWTIME[7],
            SEARCH_TIME_8: this.CHK_NEWTIME[8],
            SEARCH_TIME_9: this.CHK_NEWTIME[9],
            SEARCH_TIME_10: this.CHK_NEWTIME[10],
            SEARCH_TIME_11: this.CHK_NEWTIME[11],
            SEARCH_TIME_12: this.CHK_NEWTIME[12],
            SEARCH_TIME_13: this.CHK_NEWTIME[13],
            SEARCH_TIME_14: this.CHK_NEWTIME[14],
            SEARCH_TIME_15: this.CHK_NEWTIME[15],
            SEARCH_TIME_16: this.CHK_NEWTIME[16],
            SEARCH_TIME_17: this.CHK_NEWTIME[17],
            SEARCH_TIME_18: this.CHK_NEWTIME[18],
            SEARCH_TIME_19: this.CHK_NEWTIME[19],
            SEARCH_TIME_20: this.CHK_NEWTIME[20],
            SEARCH_TIME_21: this.CHK_NEWTIME[21],
            SEARCH_TIME_22: this.CHK_NEWTIME[22],
            SEARCH_TIME_23: this.CHK_NEWTIME[23],
            RANGE: this.SEL_NEWUPDOWN,
            REC_TIME: this.CALL_TIME,
          },
          {
            head: {
              SERVICE: this.HEADER_SERVICE,
              METHOD: this.HEADER_METHOD,
              TYPE: this.HEADER_TYPE,
              ROW_CNT: DATA_NUM,
              PAGES_CNT: this.pagination.page,
            },
          }
        )
        .then((response) => {
          this.contactDetail = [];

          if (this.selectedRow != "") {
            this.activeRow(this.selectedRow, false);
            this.selectedRow = "";
          }

          let jsonObj = JSON.parse(JSON.stringify(response.data.DATA));

          if(DOWN_YN == 'Y'){
            this.downGridDataText = jsonObj;
          } else {
            this.gridDataText = jsonObj;
          }

          head = JSON.stringify(response.data.HEADER);
          dataa = JSON.stringify(response.data.DATA);
        })
        .catch((err) => {
          alert(err);
        });
      this.HEADERResult = head;
      this.DATAResult = dataa;

      if(DOWN_YN == 'Y'){
        this.exportExcel('downTable', '상담이력조회 목록');
      }
    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    chgChkTime(){
      let TimeArray = this.CHK_NEWTIME;
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < TimeArray.length; i++){
        if(TimeArray[i] != "" || TimeArray[i] != null){
          let m = parseInt(TimeArray[i]);
          this.CHK_NEWTIME[m] = TimeArray[i];
        }
      }
     this.CHK_NEWTIME.splice(24,1);
    },

    removeDropTime(item) {
      this.CHK_NEWTIME.splice(parseInt(item.value), 1);

      let TimeArray = this.CHK_NEWTIME;
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < TimeArray.length; i++){
        if(TimeArray[i] != "" || TimeArray[i] != null){
          let m = parseInt(TimeArray[i]);
          this.CHK_NEWTIME[m] = TimeArray[i];
        }
      }

      this.chkNoTime();
    },

    allClick() {
      this.CHK_NEWTIME = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];
    },

    allCloseClick() {
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
    },

    async selectTime() {
      if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
        this.showAlert("회사를 먼저 선택해주세요.");
        return;
      }
      await api
        .post(
          "api/statistics/chat/common/start-job-time/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "statistics.chat.common.start-job-time",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var ST_TIME = response.data.DATA[0].WORK_START_TIME;

          var ST_HOUR = ST_TIME.substr(0, 2);
          this.ST_JOB_TIME = ST_HOUR;
        })
        .catch((err) => {
          alert(err);
        });

      await api
        .post(
          "api/statistics/chat/common/end-job-time/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "statistics.chat.common.end-job-time",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var END_TIME = response.data.DATA[0].WORK_END_TIME;

          var END_HOUR = END_TIME.substr(0, 2);
          this.END_JOB_TIME = END_HOUR;
        })
        .catch((err) => {
          alert(err);
        });

      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];

      for (
        var i = parseInt(this.ST_JOB_TIME);
        i <= parseInt(this.END_JOB_TIME);
        i++
      ) {
        if (i < 10) {
          let DB_TIME = "0" + i;
          this.CHK_NEWTIME[i] = DB_TIME;
        } else {
          this.CHK_NEWTIME[i] = i + "";
        }
      }
    },

    selectTurn() {
      var NEWTIME = [];

      for (var i = 0; i < 24; i++) {
        NEWTIME[i] = this.CHK_NEWTIME[i];
        if (i < 10) {
          if (NEWTIME[i] != "") {
            NEWTIME[i] = "";
          } else if (NEWTIME[i] == "") {
            let DB_TIME = "0" + i;
            NEWTIME[i] = DB_TIME;
          }
        } else {
          if (NEWTIME[i] != "") {
            NEWTIME[i] = "";
          } else if (NEWTIME[i] == "") {
            NEWTIME[i] = i + "";
          }
        }
      }
      this.CHK_NEWTIME = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
      this.CHK_NEWTIME = NEWTIME;
    },
  },

  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    ...mapGetters({
      //store에 저장된 사용자의 company 정보를 가져온다
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
    popupParams() {
      return (this.params = {
        userId: this.userId,
        userNm: this.userNm,
        asp_cust_key: this.asp_cust_key,
      });
    },
  },
};
</script>

<style></style>
